<template>
  <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">  
      <DashboardHome />
  </div>
   
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Account',
components: {
  DashboardHome: defineAsyncComponent( () => import('@/components/Profile/DashboardHome.vue') ),
},
}
</script>